import styled from "styled-components";
const MontserratRegular = "Montserrat-Regular";
const MontserratSemiBold = "Montserrat-SemiBold";
const BoldFont = "Rubik-Medium";
const RegularFont = "Rubik-Light";
const ExtraBoldFont = "Rubik-Bold";
const BoldRubikFont = "Rubik-Medium";
const MediumFont = "Rubik-Regular";
import Scurve from "/public/images/WellnessPage/scurve.svg";
import {
  CoreFeatureSection,
  RowResponsiveWrapper,
  RowWrapper,
} from "../WellnessPageV2/styles";

const ButtonLearnMoree = styled.button`
  width: ${(props) => (props.tryFree ? "190px" : "190px")};
  height: 60px;
  background-color: #f6b479;
  color: ${(props) => (props.tryFree ? "#1573B9" : "#fff")};
  display: ${({ display }) => display};
  padding: ${(props) =>
    props.paddingVertical ? `${props.paddingVertical}` : "10px 10px"};
  border-radius: 3px;
  border: none;
  font-size: 18px;
  line-height: 24px;
  font-family: Rubik-Medium;
  font-style: normal;
  box-shadow: 4px 6px 5px -1px rgba(0, 0, 0, 0.11);
  cursor: ${({ disabled }) =>
    disabled ? "not-allowed !important" : "pointer"};

  &:hover,
  &:focus,
  &:active {
    outline: none;
  }

  @media (max-width: 720px) {
    width: 80%;
    width: 320px;
    height: 50px;
  }
  @media (max-width: 350px) {
    width: 90%;
  }

  @media (min-width: 1420px) {
    font-size: 18px;
    padding: 15px 10px;
  }
  @media (min-width: 1201px) {
    width: 36%;
    width: 190px;
    font-size: 18px;
    padding: 10px 10px;
  }
`;
const ButtonWatchVideo = styled.div`
  width: 190px;
  height: 60px;
  margin-right: 20px;
  color: rgb(159,201,137);
  border: 2px solid rgb(159,201,137);
  background-color: #fff;
  display: flex;
  border-radius: 3px;
  font-size: 18px;
  line-height: 24px;
  font-family: Rubik-Medium;
  font-style: normal;
  box-shadow: 4px 6px 5px -1px rgba(0, 0, 0, 0.11);
  cursor: ${({ disabled }) =>
    disabled ? "not-allowed !important" : "pointer"};
  justify-content: space-evenly;
  align-items: center;
  img {
    width: auto !important;
    height: 24px !important;
  }
  @media (max-width: 720px) {
    width: 320px;
    height: 50px;
    margin-right: 0;
    margin-bottom: 15px;
  }
  @media (max-width: 350px) {
    width: 90%;
  }
`;

const TabData = styled.div`
  padding: 160px 0px 30px;
  width:100%;
  max-width: 1200px;
  display:flex;
  align-items:center;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  .responsiveWhyButton{
    display:none;
    >button{
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      width: 100%;
      margin-left: 0;
    }
  }
  >div:nth-child(2){
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    img {
        width: 80%;
    }
  }
  > div:last-child{
    float: left;
    display: flex;
    flex: 1;
    justify-content: flex-start;
    flex-direction: column;
    img {
      width: 13%;
      margin-left:16px;
      margin-bottom:10px;
    }
    > p:first-child{
        width:100%;
        font-style: normal;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: 0;
        color:#69C2FF;
        margin-top: 10px;
        padding-left: 19px;
        font-family: ${MontserratSemiBold};
     }
    > p {
        width:100%;
        font-style: normal;
        font-size: 18px;
        line-height: 26px;
        color: #24395a;
        margin-top: 10px;
        padding-left: 19px;
        font-family: ${MontserratRegular};
        @media(max-width: 500px){
          font-family: ${MontserratRegular};
          text-align: justify;
          padding-right: 12px;
          font-weight: 400;
          font-size: 15px;
          font-style: normal;
          line-height: 18px;
          padding-left: 0px;
          padding-right: 0px;
          text-align: left;
        }
     }
    > h1 {
        font-size: 30px;
        margin-bottom: 25px;
        color: #24395a;
        font-family: Rubik-bold;
        font-style: normal;
        letter-spacing: 0;
        line-height: 36px;
        word-break: break-word;
        width: 100%;
        padding-left: 19px;
        text-transform: capitalize;
        margin-top: 0;
        @media(max-width: 500px){
          text-align: left;
          margin: 0;
        }
    } 
  }
  @media(max-width:1000px){
    >div{
      >h1{
    font-size: 24px;
      }
    }
  }
  @media(max-width:850px){
    flex-direction: column-reverse;
    padding: 0px 20px 80px;
    margin: 0;
    >div:nth-child(2){
      margin-top: 40px;
      >img{
        margin: 0;
        width: 100%;
      }
    }
    >div:last-child{
      width: 100%;
      text-align: center;
      display: flex;
      align-items: center;
      padding: 0 10px;
      margin-top: 60px;
      >p:first-child{
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        font-family: 'Montserrat-Regular';
        line-height: 19px;
        margin-bottom: 26px;
        text-align: left;
        padding-left: 0px;
      }
      >h1{
        padding: 0;
        font-weight: bold;
        font-size: 24px;
        line-height: 33px;
        text-align: left;
      }
      >img{
        margin-left: 0;
        margin-top: 41px;
        margin-bottom: 41px;
        margin-right: auto;
        width: 78px;
      }
      >p:last-child{
        font-weight: normal;
        font-size: 15px;
        line-height: 20px;
        padding: 0;
      }
      >button{
        display: none;
      }
  }
  .responsiveWhyButton{
    display: block;
    margin: 0 auto;
    width: 94%;
  }
}

@media(max-width:500px){
  >div{
    >h1{
      font-size: 13px;
      line-height: 19px;
    }
    >p{
      font-size: 11px;
      line-height: 16px;
    }
    p:last-child{
      font-weight: normal;
      font-size: 15px;
      line-height: 20px;
      padding: 0;
      text-align: justify;
    }
  }
  >div:last-child{
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    padding: 0 10px;
    margin-top: 56px;
  }
`;

const Button2 = styled.button`
  width: ${(props) => (props.tryFree ? "200px" : "190px")};
  margin-top: 20px;
  background-color: ${(props) => (props.tryFree ? "#fff" : "#69C2FF")};
  color: ${(props) => (props.tryFree ? "#1573B9" : "#fff")};
  display: ${({ display }) => display};
  padding: ${(props) =>
    props.paddingVertical ? `${props.paddingVertical}` : "12px 10px"};
  border-radius: 3px;
  border: none;
  font-size: 16px;
  font-family: Rubik-Medium;
  font-style: normal;
  line-height: 21px;
  margin-left: 19px;
  cursor: ${({ disabled }) =>
    disabled ? "not-allowed !important" : "pointer"};
  box-shadow: 4px 6px 5px -1px #0000001c;
  &:hover,
  &:focus,
  &:active {
    outline: none;
  }

  @media (max-width: 1000px) {
    margin-top: ${(props) => (props.tryFree ? props.marginTop : "35px")};
  }

  @media (max-width: 500px) {
    margin-top: 50px;
  }
`;

const Section = styled.div`
  margin: 0 auto;
  height: 530px;
  width: 100%;
  max-width: 1200px;
  display: block;
  font-family: ${RegularFont};
  display:flex;
  align-items: flex-start;
  position: relative;
  padding-top: 84px;
  margin-bottom: -70px;
  @media (max-width: 1201px) {
    padding-top: 0px;
  }
  @media (max-width: 930px) {
    flex-flow: column;
    height: 1100px;
    padding-top: 30px;
  }
  @media(max-width: 700px){
    display:flex
    margin-bottom: 0;
    height: auto;
    flex-direction:column;
  }
  @media(max-width: 2900px){
    img{
      height: 0px;
      width: 0px;
    }
  }
  @media(max-width: 1000px){
    img{
      width: 100%;
      height: auto;
    }
  }
`;

const IntroTextContainer = styled.div`
  display: inline-block;
  float: left;
  width: 50%;
  letter-spacing: 1.5px;
  padding: 0px 10px 30px 0px;
  h1 {
    margin-bottom: 31px;
    font-family: ${BoldFont};
    letter-spacing: 0;
    margin-top: 0px;
    font-weight: 700;
    color: rgb(36, 57, 90);
    font-size: 48px;
    line-height: 50px;
    font-weight: 600;
    text-align: left;
  }
  p {
    line-height: 22px;
    font-family: ${MontserratRegular};
    letter-spacing: 0;
    font-weight: 500;
    margin: 30px 0 50px 0;
    color: rgb(36, 57, 90);
    font-size: 18px;
    line-height: 31px;
  }
  .wrap {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .divider {
    width: 75px;
    > img {
      width: 100%;
      height: 5px;
    }
  }
  @media (max-width: 1201px) {
    width: 80%;
    padding: 0px 10px 30px 80px;

    h1 {
      margin-bottom: 25px;
      font-family: ${BoldFont};
      letter-spacing: 0;
      margin-top: 10px;
      font-weight: 700;
      text-transform: uppercase;
      color: rgb(36, 57, 90);
      font-size: 36px;
      line-height: 50px;
      font-weight: 600;
      text-align: left;
    }
    p {
      line-height: 22px;
      font-family: ${MontserratRegular};
      letter-spacing: 0;
      font-weight: 500;
      margin: 30px 0 30px 0;
      color: rgb(36, 57, 90);
      font-size: 16px;
      line-height: 31px;
    }
    .wrap {
      width: 60%;
    }
  }
  @media (max-width: 930px) {
    width: 100%;
    .wrap {
      width: 45%;
    }
  }
  @media (max-width: 720px) {
    width: 100%;
    text-align: left;
    > div:last-child {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }

  @media (max-width: 500px) {
    height: 415px;
    margin-bottom: 100px;
    .wrap {
      width: 100%;
    }
    padding: 10px;
    padding-top: 20px;
    width: 100%;
    > h1 {
      font-size: 36px;
      font-family: "Rubik-Bold";
      font-style: normal;
      font-weight: bold;
      line-height: 40px;
      margin-left: 26px;
      margin-bottom: 22px;
    }
    .divider {
      margin-left: 26px;
    }
    > p {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      margin-left: 20px;
      margin: 20px;
      text-align: justify;
      font-family: Montserrat-Medium;
      font-style: normal;
    }
  }

  @media (min-width: 1420px) {
    h1 {
      max-width: unset;
      letter-spacing: 1px;
      text-align: unset;
      font-family: ${BoldFont};
      letter-spacing: 0;
      font-weight: 700;
      text-transform: uppercase;
      color: rgb(36, 57, 90);
      font-size: 48px;
      line-height: 50px;
      font-weight: 600;
      text-align: left;
    }
    p {
      line-height: 22px;
      font-family: ${MontserratRegular};
      letter-spacing: 0;
      font-weight: 500;
      margin: 25px 0 35px 0;
      color: rgb(36, 57, 90);
      font-size: 18px;
      line-height: 31px;
    }
    .wrap {
      margin-top: 20px;
    }
  }
  @media (min-width: 1500px) {
    padding: 0px 10px 0px 0px;
  }
`;

const PosterImage = styled.div`
  display: inline-block;
  float: left;
  padding: 0 0px 0 0px;
  width: 60%;
  text-align: center;

  img {
    width: 100%;
    margin-bottom: -35px;
    max-height: 800px;
    min-height: 420px;
    position: relative;
    height: 500px;
    width: auto;
    top: -52.5px;
  }

  @media (max-width: 1201px) {
    width: 50%;
    padding: 0px;

    img {
      width: 100%;
      margin-bottom: -70px;
      min-height: unset;
      max-height: unset;
      max-height: 800px;
      min-height: 420px;
      position: relative;
      height: 450px;
      width: auto;
      top: 37px;
      left: -70px;
    }
  }

  @media (max-width: 1000px) {
    img {
      display: none;
    }
  }
  @media (max-width: 930px) {
    width: 100%;
    padding: 70px 30px 0px 30px;
  }
  @media (max-width: 768px) {
    padding: 30px 30px 0px 30px;
    img {
      top: 90px;
      position: relative;
      margin-bottom: -15px;
    }
  }
  @media (max-width: 600px) {
    padding: 30px 30px 0px 30px;
    img {
      top: 25px;
      position: relative;
      margin-bottom: -15px;
    }
  }
  @media (max-width: 500px) {
    img {
      margin-bottom: -50px;
      min-height: 250px;
      height: 200px;
      position: relative;
      margin: 0px;
      top: 202px;
      height: 200px;
      margin-bottom: 200px;
      left: -20px;
      top: 70px;
    }
  }
  @media (min-width: 1400px) {
    > img {
      max-height: 650px;
    }
  }
  @media (max-width: 350px) {
    margin-top: 20px;
  }
`;
const MainResources = styled.div`
  width: 100%;
  position: relative;
  float: left;
  .line {
    position: absolute;
    left: 0px;
    margin-top: 30px;
    > svg {
      fill: ${({ color }) => color};
    }
  }
  > h1 {
    width: 65%;
    display: block;
    font-size: 36px;
    text-transform: capitalize;
    color: #24395a;
    line-height: 36px;
    font-style: normal;
    font-family: Rubik-Medium;
    margin-bottom: 20px;
    margin: 0px;
  }
  > h2 {
    width: 65%;
    display: block;
    font-size: 36px;
    text-transform: capitalize;
    color: #24395a;
    line-height: 36px;
    font-style: normal;
    font-family: Rubik-Medium;
    margin-bottom: 20px;
    margin: 0px;
  }
  > h3 {
    width: 65%;
    display: block;
    font-size: 36px;
    text-transform: capitalize;
    color: #24395a;
    line-height: 36px;
    font-style: normal;
    font-family: Rubik-Medium;
    margin-bottom: 20px;
    margin: 0px;
  }
  > h3 {
    width: 65%;
    display: block;
    font-size: 36px;
    text-transform: capitalize;
    color: #24395a;
    line-height: 36px;
    font-style: normal;
    font-family: Rubik-Medium;
    margin-bottom: 20px;
    margin: 0px;
  }
  > h2 {
    width: 65%;
    display: block;
    font-size: 36px;
    text-transform: capitalize;
    color: #24395a;
    line-height: 36px;
    font-style: normal;
    font-family: Rubik-Medium;
    margin-bottom: 20px;
    margin: 0px;
  }
  .scurve {
    float: left;
    width: 100%;
    background-image: url(${Scurve});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    transform: translate(-90px, 90px);
    @media (max-width: 1400px) {
      transform: translate(-20px, 90px);
    }
  }
  @media (max-width: 500px) {
    > h1 {
      font-weight: 500;
      font-size: 26px;
      width: 85%;
      margin: 0 20 20px 20;
      padding-left: 20px;
      margin-left: 20px;
      text-align: left;
      font-family: "Rubik";
      padding-left: 0px;
    }
    .line {
      padding-left: 20px;
      margin-top: 0px;
    }
  }

  @media (max-width: 435px) {
    > h1 {
      font-family: "Rubik-Medium";
    }
  }
`;
const Resources = styled.div`
  float: left;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: 60px;
  flex-direction: ${({ flexDirection }) => flexDirection};
  justify-content: space-between;
  margin-bottom: 150px;
  > div:first-child {
    > div {
      padding-top: 35px;
    }
    .responsive {
      display: none;
    }
  }
  > div {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    > div:first-child {
      display: flex;
      align-items: center;
      padding-top: ${({ padding }) => (padding ? "0px" : "20px")};
      padding-bottom: ${({ padding }) => (padding ? "0px" : "0px")};
      flex-direction: row;
      .title {
        font-size: 30px;
        color: ${({ col }) => col};
        font-family: Rubik-Medium;
        font-style: normal;
        line-height: 58px;
        letter-spacing: 0;
        display: inline;
        word-break: break-word;
        width: 100%;
        padding-left: 19px;
      }
      > img {
        width: 60px;
        height: 60px;
        @media (max-width: 700px) {
          margin-left: 20px;
        }
      }
      >div{
        > img {
        width: 60px;
        height: 60px;
        @media (max-width: 700px) {
          margin-left: 20px;
        }
      }
      }
    }
    > div {
      width: 100%;
      display: flex;
      flex-direction: column;
      cursor: pointer;
    }
    > div:nth-child(3) {
      > div {
        > div:last-child {
          > img {
            width: 100%;
          }
        }
      }
    }
  }
  > div:last-child {
    width: 100%;
    align-items: ${({ align }) => align};
    img {
      float: left;
      width: 500px;
      height: auto;
    }
  }
  @media (max-width: 1000px) {
    flex-direction: column;
    > div {
      width: 100%;
      padding: 0 62px;
      margin-bottom: 20px;
    }
    > div:last-child {
      width: 100%;
      margin-bottom: 0px;
      margin-top: 30px;
      align-items: center;
      img {
        width: 200px;
        display: flex;
        margin: 0 auto;
        float: unset;
      }
    }
  }
  @media (max-width: 600px) {
    padding: 0;
    margin-top: 10px;
    margin-bottom: 40px;
    > div {
      margin-top: 20px;
    }
    > div:first-child {
      padding: 0;
      > div {
        padding: 0;
      }
      .normal {
        display: none;
      }
      .responsive {
        display: block;
        padding: 0px 10px;
        .responsive-image {
          margin-top: 25px;
        }
      }
    }
    > div:last-div {
      display: none;
    }
    .image {
      display: none;
    }
  }
`;

const DemoText = styled.div`
 font-family: Rubik;
 font-size: 18px;
 line-height: 20px;
 text-align:left;
 margin-left:75px;
 width:90%;
 color: white;
 margin-bottom:40px;
 @media(max-width:500px){
  width:100%
  text-align:center;
  margin-left:0px;
 }
 @media(max-width:700px){
  width:100%
  text-align:center;
  margin-left:0px;
 }
`;

const WatchDemoCard = styled.div`
  max-width: 100%;
  margin: auto;
  .topcard-demo-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    @media (max-width: 1000px) {
      padding: 25px;
    }
    .topcard-demo {
      width: 100%;
      max-width: 1200px;
      // height: 200px;
      border-radius: 6px;
      display: flex;
      margin-top: 20px;
      background: linear-gradient(90deg, #102b52 29.41%, #006fbc 100%);
      margin-bottom: 25px;
      @media (max-width: 1000px) {
        display: flex;
        flex-wrap: wrap;
        height: 100%;
      }
      .topcard-pic {
        margin-top: 15px;
        margin-bottom: 15px;
        margin-left: 15px;
        @media (max-width: 1000px) {
          width: 100%;
          height: 100%;
          margin: 0px;
        }
        .enter-img {
          @media (max-width: 1000px) {
            width: 90%;
            display: flex;
            margin: auto;
            height: 90%;
            margin-top: 15px;
          }
        }
      }
      .topcard-content {
        @media (max-width: 1000px) {
          width: 100%;
        }
        .upper-content {
          color: #f6b479;
          font-family: "Rubik-Medium";
          font-style: normal;
          font-size: 24px;
          line-height: 24px;
          display: flex;
          align-items: center;
          text-align: center;
          text-transform: capitalize;
          margin-top: 38px;
          margin-left: 25px;
          @media (max-width: 1000px) {
            margin: auto;
            justify-content: center;
            margin-top: 15px;
          }
        }
        .lower-content {
          font-family: "Montserrat-Regular";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          text-transform: capitalize;
          color: #ffffff;
          margin-top: 25px;
          margin-bottom: 37px;
          margin-left: 25px;
          @media (max-width: 1000px) {
            width: auto;
            display: block;
            text-align: center;
            margin: auto;
            margin-top: 15px;
            font-size: 13px;
            display: none;
          }
          @media (max-width: 400px) {
            font-size: 12px;
          }
          @media (max-width: 360px) {
            font-size: 11px;
          }
          > p {
            margin-bottom: 2px;
          }
        }
        .lower-mobb-content {
          width: auto;
          display: block;
          text-align: center;
          margin: auto;
          margin-top: 15px;
          font-size: 13px;
          font-family: "Montserrat-Regular";
          text-transform: capitalize;
          color: #ffffff;
          padding-left: 16px;
          padding-right: 16px;
          @media (min-width: 1200px) {
            display: none;
          }
        }
      }
      .demo-btn {
        width: 250px;
        height: 60px;
        background: #f6b479;
        box-shadow: 9px 12px 13px rgba(0, 0, 0, 0.12);
        border-radius: 5px;
        margin: auto;
        margin-right: 32px;
        border: none;
        @media (max-width: 1000px) {
          margin: auto;
          margin-top: 20px;
          margin-bottom: 20px;
        }
        .watch-text {
          width: 196px;
          height: 24px;
          font-family: "Rubik-Medium";
          font-size: 18px;
          line-height: 24px;
          display: flex;
          align-items: center;
          text-align: center;
          text-transform: capitalize;
          color: #ffffff;
          margin: 0px 28px 0px 24px;
          @media (max-width: 280px) {
            width: auto;
          }
        }
      }
    }
  }
`;

const CoreFeatureSectionHeading = styled.div`
  width: 100%;
  max-width: 1200px;
  display: flex;
  // padding-top: 50px;
  padding-bottom:50px;
  margin: ${({ margin }) => (margin ? margin : "")};
  >div{
      width:100%;
      justify-content:center;
      text-align: center;
    >p{
      color:#F6797D;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      letter-spacing: 0;
      font-family:  ${MontserratSemiBold};

    }
    .subtext{
      color:#253A5B;
      font-style: normal;
      font-weight:500;
      font-size: 16px;
      line-height: 26px;
      text-align: center;
      letter-spacing: 0;
      margin-bottom:20px
      font-family:Montserrat-Regular;

    }
    >h1{
      margin-top:75px;
      margin-bottom:20px;
      font-size: 40px;
      line-height: 48px;
      letter-spacing: 0.03em;
      color: #24395A;
      text-align: center;
      font-style: normal;
      font-family: Rubik-bold;
    }
    >h2{
      margin-top:75px;
      margin-bottom:20px;
      font-size: 40px;
      line-height: 48px;
      letter-spacing: 0.03em;
      color: #24395A;
      text-align: center;
      font-style: normal;
      font-family: Rubik-bold;
    }
  }
  @media(max-width: 600px){
    padding-top: 0;
    padding-bottom:20px;
    >div{
      text-align: center;
      .subtext{
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        margin-bottom:20px;
      }
      >h1{
        font-weight: bold;
        font-size: 32px;
        line-height: 41px;
        font-style: normal;
        font-size: normal;
        font-family: 'Rubik-Bold';
        font-weight: 700;
        line-height: 41px;
        word-spacing: 10px;
        margin-top:25px
        padding: 0px 7px;
      }
    }
  }
`;

const CoreFeatureSectionHeadingV1 = styled.div`
  width: 100%;
  max-width: 1200px;
  display: flex;
  // padding-top: 50px;
  padding-bottom:50px;
  margin: ${({ margin }) => (margin ? margin : "")};
  >div{
      width:100%;
      justify-content:center;
      text-align: center;
    >p{
      color:#F6797D;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      letter-spacing: 0;
      font-family:  ${MontserratSemiBold};

    }
    .subtext{
      color:#253A5B;
      font-style: normal;
      font-weight:500;
      font-size: 16px;
      line-height: 26px;
      text-align: center;
      letter-spacing: 0;
      margin-bottom:20px
      font-family:Montserrat-Regular;

    }
    >h1{
      margin-top:20px;
      margin-bottom:20px;
      font-size: 40px;
      line-height: 48px;
      letter-spacing: 0.03em;
      color: #24395A;
      text-align: center;
      font-style: normal;
      font-family: Rubik-bold;
    }
    >h2{
      margin-top:20px;
      margin-bottom:20px;
      font-size: 40px;
      line-height: 48px;
      letter-spacing: 0.03em;
      color: #24395A;
      text-align: center;
      font-style: normal;
      font-family: Rubik-bold;
    }
  }
  @media(max-width: 600px){
    padding-top: 0;
    padding-bottom:20px;
    >div{
      text-align: center;
      .subtext{
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        margin-bottom:20px;
      }
      >h1{
        font-weight: bold;
        font-size: 32px;
        line-height: 41px;
        font-style: normal;
        font-size: normal;
        font-family: 'Rubik-Bold';
        font-weight: 700;
        line-height: 41px;
        word-spacing: 10px;
        margin-top:25px
        padding: 0px 7px;
      }
    }
  }
`;

const CoreWrapper = styled.div`
width: 100%;
  // max-width: 1284px;
  max-width: 1200px;
  margin: 150px 0 75px 0;
  margin-left: auto;
  margin-right: auto;  
  //background-color: red;
  // float: left;
  >div:first-child{
    text-align:center;
    margin-bottom: 75px;
    >p{
      font-style: normal;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      letter-spacing: 0;
      color: #69C2FF;
      font-family: ${MontserratSemiBold}
    }
    >h1{
      font-family: ${ExtraBoldFont};
      font-size: 40px;
      line-height: 48px;
      text-align: center;
      letter-spacing: 0.03em;
      text-transform: capitalize;
      color: #24395A;
    }
    >h2{
      font-family: ${ExtraBoldFont};
      font-size: 40px;
      line-height: 48px;
      text-align: center;
      letter-spacing: 0.03em;
      text-transform: capitalize;
      color: #24395A;
    }
    >h2{
      font-family: ${ExtraBoldFont};
      font-size: 40px;
      line-height: 48px;
      text-align: center;
      letter-spacing: 0.03em;
      text-transform: capitalize;
      color: #24395A;
    }
    
  }
  >div:nth-child(3){
    width:100%;
    // padding: 0px 80px 0px 80px;
    padding: 10px
    height:550px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    background-color: rgba(105, 194, 255, 0.1);
    >div:first-child{
      width:65%;
      margin-top: 44px;
      //padding: 0 7px 50px;
      >h1{
        font-family: ${BoldFont};
        font-size: 30px;
        line-height: 54px;
        letter-spacing: 0;
        color: #69c2ff;
        margin: 0;
      }
      >h2{
        font-family: ${MontserratSemiBold};
        margin: 0px 0 30px;
        font-style: normal;
        font-size: 24px;
        line-height: 29px;
        color: #24395a;
        text-transform: uppercase;
      }
      >img{
        margin-bottom: 30px;
      }
      >div{
        height: auto;
        >p{
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 26px;
          color: #3A3A3A;
          font-family: ${MontserratRegular};
          text-align: center;
        }
      }
    }
    >div:last-child{
      width:auto;
      height: 350px;
      margin-top: 10px;
      display: flex;
      justify-content: center;
      >img{
        position: relative;
        top: -36px;
        width: auto;
        height: 400px;
      }
    }
  }
  .responsive-core{
    display: none;
    width: 100%;
    padding: 0px 70px 0px 110px;
    flex-direction: column;
    >div{
      width: 100%;
      padding: 0;
      >div:first-child{
        display: flex;
        width: 100%;
      }
      >div:nth-child(2){
        width: 100%;
        >div:first-child{
          margin-bottom: 60px;
          width: 100%;
        }
        >div:last-child{
          width: 100%;
          margin-left: -10px;
          text-align: center;
          display: flex;
          justify-content: center;
          >img{
            // width: auto;
            width: 100%;
            // height: 450px;
            height: auto;
          }
        }
      }
      >div:last-child{
        width:100%;
        text-align:center;
      }
    }
  }
  @media(max-width:1000px){
    .responsive-core{
      >div{
     
          >div:nth-child(2){
            >div:first-child{
              margin-bottom: 20px;
            }
    
        }
      }
    }
    >div:nth-child(3){
      >div:first-child{
        padding: 0 50px;
        >button{
          margin-top: 30px;
          width: 60%;
        }
      }
      >div:last-child{
        >img{
          width:80%;
        }
      }
    }
  }
  @media(max-width: 720px){
    margin: 0;
    .responsive-core{
      display: flex;
      >div{
        .selectedCoreContent{
          margin-left: 10px;
          padding: 5px;
          >div:first-child{
            >button{
              margin-top: 30px;
            }
          }
        }
        .coreDivider{
          >img{
            width: 100%;
          }
        }
      }
    } 
    >div:nth-child(2){
      display:none;
    }
    >div:nth-child(3){
      display:none;
    }
  }
  @media(max-width: 500px){
    margin-top: 60px;
    padding: 0px 20px;
    >div:first-child{
      >p{
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.08em;
      }
      >h1{
        font-size: 28px;
        line-height: 36px;
        letter-spacing: 0.03em;
        margin-bottom: 30px;
        font-family: Rubik-Medium;
font-weight: 700;
text-transform: capitalize;
color: #24395A;
      }
      >h3{
        font-size: 28px;
        line-height: 36px;
        letter-spacing: 0.03em;
        margin-bottom: 30px;
        font-family: Rubik-Medium;
font-weight: 700;
text-transform: capitalize;
color: #24395A;
      }
    }
    .responsive-core{
      padding: 0 10px;
      padding-top: 30px;
      >div{
        >div{
          h1{
            font-size: 20px;
            letter-spacing: -0.025em;
            line-height: 24px;
            font-family: Rubik;
font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 24px;
text-align: left;
letter-spacing: -0.025em;
          }
          h3{
            font-size: 20px;
            letter-spacing: -0.025em;
            line-height: 24px;
            font-family: Rubik;
font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 24px;
text-align: left;
letter-spacing: -0.025em;
          }
        }
      }
      .selectedCoreContent{
        >div:first-child{
        }
          >h2{
            font-size: 16px;
            line-height: 20px;
            margin-top: 0;
            font-family: Montserrat;
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 20px;
          }
          >div{
            >p{
              line-height: 18px;
              margin-top: 33px;
              font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 15px;
line-height: 18px;
text-align: left;
            }
          }
        }
      }
    }
  }
`;

const CustomListItem = styled.li`
  margin: 10px 0px 0px 0px;
  padding-bottom: 17px;
  border-bottom: ${({ active }) =>
    active ? "3px solid #F6797D" : "3px solid transparent"};
  box-shadow: ${({ active }) =>
    active ? "0 30px 40px -15px #69C2fF" : "none"};
  border-radius: unset;
  text-align: center;
  cursor: pointer;
  list-style-type: none;
  position: relative;
  display: ${({ showTab }) => (showTab ? "inline-block" : "none")};
  color: #49b5d6;
  > a {
    float: left;
    text-transform: capitalize;
    font-size: 16px;
    color: ${({ active }) => (active ? "#69C2fF" : "#9c9c9c")};
    font-family: ${({ active }) =>active ? "Rubik-Regular" : "Rubik-Regular !important"};
    > svg {
      fill: ${({ active }) => (active ? "#F6797D" : "#C4C4C4")};
      width: 10px;
      margin-left: 3px;
    }
    &:hover, &:focus, &:active {
      text-decoration: none;
      color: ${({ active }) => (active ? "#F6797D" : "#C4C4C4")};
      > svg {
        fill: #F6797D;
        width: 10px;
        margin-left: 3px;
      }
    }@media (max-width: 1000px) {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom 15px;
  padding-bottom 25px;
}
  }
  &:hover, &:focus, &:active {
    border-bottom: 3px solid #F6797D;
    > a {
      text-decoration: none;
      color: #F6797D;
      font-family: 'Rubik-Medium';
    }
  }
  @media (max-width: 1200px) {
    min-width: 120px;
    border-left: ${({ active }) => active ? "5px solid #F6797D" : "5px solid transparent"};
    border-bottom: none;
    text-align: left;
    > a {
      color: ${({ active }) => (active ? "#F6797D" : "#C4C4C4")};
      > svg {
        fill: ${({ active }) => (active ? "#F6797D" : "#C4C4C4")};
      }
    }
    &:hover, &:focus, &:active {
      border-bottom:none;
        >a {
          text-decoration: none;
          color: #F6797D;
        }
    }
  }

  &:hover ul {
    display: block;
  }
  .platform-div {
    list-style: none;
    display: none;
    position: absolute;
    padding-left: 118px;
    height: 200px;
    flex-direction: row;
    width: 100vw;
    top: 42px;
    left: -234px;
    background-color: white;
    font-family: ${BoldRubikFont};
    z-index: 1;
    >div:last-child{
      cursor: default;
    }
    >div{
      width:17%;
      padding-top: 30px;
      padding-bottom: 30px;
      text-align: left;
      >h1{
        font-family: ${BoldRubikFont};
        font-style: normal;
        font-size: 16px;
        line-height: 19px;
        margin: 5px 0;
        color: #252530;
      }
      >p{
        font-family: ${MontserratRegular};
        font-style: normal;
        font-size: 14px;
        line-height: 15px;
        color: #252530;
      }
      &:hover{
        >h1{
        color: #F6797D;
        }
        >div{
          >svg {
            fill : #F6797D;
            >path{
              fill : #F6797D;
            }
          }
        }
      }
    }
    >div:last-child{
      width:32%;
      background: rgba(246, 121, 125, 0.14);
      padding: 16px 0 5px 30px;
      >h1{
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        padding-bottom: 7px;
        color: #243A5A;
      }
      >ul{
        padding-left: 20px;
        li{
          font-family: Rubik;
          padding: 5px 0;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          color: #979797;
        }
      }
    }
  }
  &:hover .platform-div{
    display: flex;
  }
  ul > li:hover {
    color: #F6797D;
  }

  .solution-div {
    list-style: none;
    display: none;
    position: absolute;
    padding-left: 118px;
    height: 200px;
    flex-direction: row;
    width: 100vw;
    top: 42px;
    left: -234px;
    background-color: white;
    font-family: ${BoldRubikFont};
    z-index: 1;
    >div:last-child{
      cursor: default;
    }
    >div{
      width:17%;
      padding-top: 30px;
      padding-bottom: 30px;
      text-align: left;
      >h1{
        font-family: ${BoldRubikFont};
        font-style: normal;
        font-size: 16px;
        line-height: 19px;
        margin: 5px 0;
        color: #252530;
      }
      >p{
        font-family: ${MontserratRegular};
        font-style: normal;
        font-size: 14px;
        line-height: 15px;
        color: #252530;
      }
      &:hover{
        >h1{
        color: #F6797D;
        }
        >div{
          display: flex;
          >svg {
            fill : #F6797D;
            >path{
              fill : #F6797D;
            }
          }
          >h1{
            font-family: ${BoldRubikFont};
            font-style: normal;
            font-size: 16px;
            line-height: 19px;
            margin: 5px 0;
            color: #24395A;
            padding: 8px 0px 8px 0px;
          }
        }
      }
    }
    >div:last-child{
      width:32%;
      background: rgba(246, 121, 125, 0.14);
      padding: 16px 0 5px 30px;
      >h1{
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        padding-bottom: 7px;
        color: #243A5A;
      }
      >ul{
        padding-left: 20px;
        li{
          font-family: Rubik;
          padding: 5px 0;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          color: #979797;
        }
      }
    }
  }
  &:hover .solution-div{
    display: flex;
  }
  ul > li:hover {
    color: #F6797D;
  }
`;

const CustomList = styled(CustomListItem)`
  display: flex;
  width: auto;
  justify-content: center;
  margin: 0;
  border-bottom: ${({ active }) =>
    active ? "5px solid #69C2fF" : "5px solid transparent"};
  border-top: none;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-right: 80px;
  margin: 0px 40px 0px 40px;
  position: relative;
  top: 5px;
  > a {
    font-size: 20px;
    font-family: Rubik-Medium;
    font-style: normal;
    line-height: 24px;
    text-align: center;
    font-weight: normal;
    padding-bottom: 10px;
  }
  &:hover,
  &:focus,
  &:active {
    border-bottom: 5px solid #69c2ff;
    border-top: none;
    > a {
      color: #69c2ff;
      font-size: 20px;
    }
  }
  @media (max-width: 1200px) {
    min-width: unset;
    border-left: none;
    > a {
      font-size: 16px;
    }
    &:hover,
    &:focus,
    &:active {
      > a {
        font-size: 16px;
      }
    }
  }
`;

const MenuLinks = styled.a`
  font-size: 16px;
  text-transform: capitalize;
  color: #979ba0;
  font-family: ${RegularFont};
  > div {
    > img {
      width: 16px;
    }
  }
  @media (max-width: 1200px) {
    > svg {
      width: 18px;
      height: 10px;
    }
  }
`;

const EmployeeRewardMain = styled.div`
display: flex;
width: 100%;
background: rgba(105,194,255,0.1);
padding: 0px 0px 75px;
margin-top: 150px;
flex-wrap:wrap;
.wrapper{
  width: 1200px;
  display: flex;
  margin:auto;
  justify-content: space-between;
  @media (max-width: 1000px) {
    width: 100%;
    padding:0px 15px;
    display: flex;
    flex-wrap: wrap;
  }
}
@media (max-width: 500px) {
  margin-top:30px
  padding:0 0 20px 0;
}
`;

const EmployeeRewardCard = styled.div`
width: calc((100% - 90px) / 3);
border-radius: 16px;
border: 1px solid rgba(105, 194, 255, 1);
flex-wrap:wrap;
background: white;
height: 300px;
display: block;
.image{
  width: 100%;
  display: flex;
  justify-content:center;
  margin: auto;
  margin-bottom: 20px;
  margin-top: 40px;
  >img{
    height:60px;
    width:60px;
  }
}
.heading{
  font-family: "Rubik-Medium";
  font-size: 24px;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(36, 58, 90, 1);
  width:100%;
  display: flex;
  text-align:center;
  justify-content: center;
  margin: 0px;
  align-items: center;
  margin-bottom: 20px;
}
.text{
  font-family: Montserrat-Regular;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(37, 58, 91, 1);
  width:100%;
  display: flex;
  text-align:center;
  justify-content: center;
  margin: 0px;
  align-items: center;
}
.text-container{
  display: flex;
  flex-wrap:wrap;
  width:100%;
}
@media (max-width: 1000px) {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom 15px;
  padding-bottom 25px;
}
@media (max-width: 500px) {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom 15px;
  padding-bottom 20px;
  .image{
    >img{
      width:80px;
      height:80px
    }
  }
}

`;

const CompanyRewardsProgram = styled(CoreFeatureSection)`
  padding: 75px 0 10px 0;
  > div {
    > h1 {
      font-size: 40px;
      margin-top: 0;
    }
    > h2 {
      font-size: 40px;
      margin-top: 0;
    }
  }
  @media (max-width: 600px) {
    padding-bottom: 0;
    > div {
      > h1 {
        text-align: left;
        font-size: 26px;
        margin: 30px 0;
        padding-left: 25px;
        line-height: 30px;
      }
      > h2 {
        text-align: left;
        font-size: 26px;
        margin: 30px 0;
        padding-left: 25px;
        line-height: 30px;
      }
    }
  }
`;
const CoreListV2 = styled.div`
  width: 100%;
  padding: 0;
  > div:first-child {
    cursor: pointer;
    > h1 {
      width: 90%;
      color: ${({ active }) => (active ? "#69C2FF" : "#24395A")};
      font-family: Rubik;
      font-weight: ${({ active }) => (active ? `500` : `400`)};

      margin-left: 15px;
    }
    > h3 {
      width: 90%;
      color: ${({ active }) => (active ? "#69C2FF" : "#24395A")};
      font-family: Rubik;
      font-weight: ${({ active }) => (active ? `500` : `400`)};

      margin-left: 15px;
    }
    > img {
      height: 10%;
      margin: auto 0;
      transform: ${({ active }) => active && "rotateX(180deg)"};
      margin-right: 15px;
    }
  }
`;

const RatingWrapperV3 = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 0px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 1000px) {
    padding: 0;
  }
  @media (max-width: 500px) {
    // margin-top: 120px;
  }
`;

const WrapperV3 = styled.div`
  width: 100%;
  margin-bottom: 50px;
  text-align: center;
  max-width: 1200px;
  margin-top: 75px;
  >p{
    font-style: normal;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    letter-spacing: 0.08em;
    color: #F6797D;
    font-family: ${MontserratSemiBold};
  }
  >h1{
    font-style: normal;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    color: #253A5B;
    font-family: ${ExtraBoldFont};
    text-transform: capitalize;
    padding-right: 8px;
  }
  >h2{
    font-style: normal;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    color: #253A5B;
    font-family: ${ExtraBoldFont};
    text-transform: capitalize;
    padding-right: 8px;
  }
  >h2{
    font-style: normal;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    color: #253A5B;
    font-family: ${ExtraBoldFont};
    text-transform: capitalize;
    padding-right: 8px;
  }
  @media(max-width: 500px){
    >h1{
      font-size: 28px;
      margin:28px
  }
`;

const RatingWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 0px 0px 0px 0px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 70px;
  @media (max-width: 1000px) {
    padding: 0;
  }
  @media (max-width: 700px) {
    // padding-top: 67px;
  }
`;

const RatingsContainer = styled.div`
  background: #EDF7FF;
  width: 100%;
  position: relative;
  margin: 43px 0;
  margin-bottom:0;
  padding-bottom:0;
  border-radius: 6px;
  text-align: center;
  float:left;
  display: flex;
  align-items: center;
  flex-direction: column;
  >img{
    width: 106px;
    height: 106px;
    border-radius: 50%;
    position: absolute;
    top: -52.8px;
    left: 46%;
  }
  .image{
    >img{
    width: 106px;
    height: 106px;
    border-radius: 50%;
    position: absolute;
    top: -52.8px;
    left: 46%;
  }
      }

  .svgContainer{
    margin: 80px auto 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    >svg{
      fill: #F8B739;
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }
  .ratingDescription{
    font-family: ${MediumFont}
    font-size: 18px
    line-height: 26px
    text-align: Center
    color: #000000;
    padding: 0 50px 30px;
    width: 80%;
    font-family: ${MontserratRegular};
    @media(max-width: 1000px){
      padding: 0;
    }
  }
  .ratingBy{
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    tex-align: Center
    margin-top: 0px;
    color: #3a3a3a;
    margin-bottom: 40px;
    @media(max-width: 1000px){
      font-family: Rubik;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 21.33px;
      font-weight: 500;
      text-align: center;
    }
  }
}
`;

const RatingsContainers = styled(RatingsContainer)`
  .ratingStars {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 80px 0 10px 0;
  }
  .ratingDescription {
    padding: 0px 50px 5px;
    margin: 13px 13px 0px 13px;
    height: 150px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #3a3a3a;
    align-items: center;
    display: flex;
    justify-content: center;
    font-family: ${MontserratRegular};
  }

  .ratingBy {
    span {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      color: #3a3a3a;
    }
  }
  @media (max-width: 1000px) {
    > img {
      left: 43%;
    }

    .image{
        >img{
        left: 43%;
      }
      }
    .ratingDescription {
      font-size: 16px;
    }
    .ratingBy {
      span {
        display: block;
        font-family: "Montserrat-Regular";
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: #3a3a3a;
      }
    }
  }

  @media (max-width: 650px) {
    .ratingDescription {
      padding-left: 0px;
      padding-right: 0px;
      font-size: 16px;
      font-weight: 400;
      font-family: "Montserrat-Regular";
      font-style: normal;
      text-align: center;
    }
    > img {
      left: 40%;
    }

    .image{
        >img{
        left: 40%;
      }
      }
  }
  @media (max-width: 500px) {
    .ratingDescription {
      padding-left: 0px;
      padding-right: 0px;
      font-size: 15px;
      font-weight: 400;
      font-family: "Montserrat-Regular";
      font-style: normal;
      text-align: center;
    }
    > img {
      left: 36%;
    }
    .image{
        >img{
        left: 36%;
      }
      }
    .ratingBy {
      font-family: "Rubik-Medium";
      font-weight: 500;
      font-size: 18px;
      line-height: 21.33px;
    }
  }

  @media (max-width: 435px) {
    .ratingBy {
      font-size: 15px;
      line-height: 10.33px;
    }
  }
`;
const RowWrapperV2 = styled(RowWrapper)``;
const RowResponsiveWrapperV2 = styled(RowResponsiveWrapper)``;

export {
  ButtonLearnMoree,
  ButtonWatchVideo,
  TabData,
  Button2,
  Section,
  IntroTextContainer,
  PosterImage,
  MainResources,
  Resources,
  DemoText,
  WatchDemoCard,
  CoreFeatureSectionHeading,
  CoreWrapper,
  CustomList,
  MenuLinks,
  EmployeeRewardMain,
  EmployeeRewardCard,
  CompanyRewardsProgram,
  RowWrapperV2,
  RowResponsiveWrapperV2,
  CoreListV2,
  RatingWrapperV3,
  WrapperV3,
  RatingsContainers,
  RatingWrapper,
  RatingsContainer,
  CoreFeatureSectionHeadingV1,
};
